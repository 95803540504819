
import {Component, Prop} from 'vue-property-decorator';
import {mixins} from "vue-class-component";
import ErrorMessageHandlerMixin from "@/misc/ErrorMessageHandler.mixins";
import {AccountType} from "@/enum/AccountType.enum";
import Restaurateur from "@/models/Restaurateur";
import vuetify from '@/plugins/vuetify';

enum SettingState {
  GENERAL = 'GENERAL',
  EMAIL = 'EMAIL',
  PASSWORD = 'PASSWORD',
  NOTIFICATIONS = 'NOTIFICATIONS',
  TWO_FACTOR = 'TWO_FACTOR'
}

interface NavItemInterface {
  label: any,
  state: SettingState,
  isAdmin: boolean
}

@Component({
  components: {
    ChangeGeneralComponent: () => import(
      /* webpackChunkName: "ChangeGeneralComponent" */
      '@/components/Settings/PersonalSettings/ChangeGeneral.component.vue'
    ),
    ChangePasswordComponent: () => import(
      /* webpackChunkName: "ChangePasswordComponent" */
      '@/components/Settings/PersonalSettings/ChangePassword.component.vue'
    ),
    ChangeEmailComponent: () => import(
      /* webpackChunkName: "ChangeEmailComponent" */
      '@/components/Settings/PersonalSettings/ChangeEmail.component.vue'
    ),
    ChangeNotificationComponent: () => import(
      /* webpackChunkName: "ChangeNotificationsComponent" */
      '@/components/Settings/PersonalSettings/ChangeNotification.component.vue'
    ),
    ChangeSecurityComponent: () => import(
      /* webpackChunkName: "ChangeSecurityComponent" */
      '@/components/Settings/PersonalSettings/ChangeSecurity.component.vue'
    )
  },
})
export default class EditProfileView extends mixins(ErrorMessageHandlerMixin) {
  @Prop({required: true})
  private user!: Restaurateur;

  private currentState = SettingState.GENERAL;

  /**
   * all the different nav items
   * @private
   */
  private navItems: NavItemInterface[] = [
    {label: this.$t('EDIT_PROFILE.PERSONAL_HEADLINES.GENERAL'), state: SettingState.GENERAL, isAdmin: false},
    {label: this.$t('EDIT_PROFILE.PERSONAL_HEADLINES.CHANGE_EMAIL'), state: SettingState.EMAIL, isAdmin: false},
    {label: this.$t('EDIT_PROFILE.PERSONAL_HEADLINES.CHANGE_PASSWORD'), state: SettingState.PASSWORD, isAdmin: false},
    {label: this.$t('EDIT_PROFILE.PERSONAL_HEADLINES.NOTIFICATIONS'), state: SettingState.NOTIFICATIONS, isAdmin: false},
    {label: this.$t('EDIT_PROFILE.PERSONAL_HEADLINES.SECURITY'), state: SettingState.TWO_FACTOR, isAdmin: true},
  ];

  /**
   * flag that indicates which selection of items is shown
   * @private
   */
  private showChangePassword = false;

  /**
   * State Bool for a Loading Indicator
   * @private
   */
  private isLoading = false;

  /**
   * State Bool for a Valid form
   * @private
   */
  private isValid = true;

  /**
   * changes the selection to the one that is passed
   * @param value
   * @private
   */
  private onSelectionChange(value: string): void {
    this.currentState = value as SettingState;
  }

  private isStateActive(value: string) {
    return this.currentState === value as SettingState;
  }

  private get isAdmin() {
    return this.$hasRole(AccountType.PB);
  }
}
